<template>
<v-layout>
    <v-app-bar
    absolute
    color="#fff"
  >
  
    <img src="@/assets/logo-pharmacall.jpg" height="56" />
    <v-spacer></v-spacer>    
  </v-app-bar>  
  <div class="home"  style="background-color:#fff">    
    <div class="home">
        <v-card
            elevation="2"
            class="home__card"
            style="text-align:justify;"
            >
            <h1 class="home__card-title">
               BASES LEGALES DE LA PROMOCIÓN
            </h1> <br/>
            <p class="forgot-password__card-subtitle" style="text-align:justify;">
            La empresa PHARMA CALL SA, en adelante el organizador, con domicilio en C/ Eduard Maristany 430-432, 08918 Badalona, Barcelona, con CIF nºB66164872, organiza una acción promocional para la venta de sus productos, que se regirá de conformidad con las siguientes bases legales:
            </p>
            <br/><h2 style="text-align:center;">1.	OBJETO.</h2><br/>
            <p class="forgot-password__card-subtitle" style="text-align:justify;">
Las presentes bases legales tienen por objeto regular la promoción llevada a cabo por el organizador, según los términos recogidos en la misma, y tiene por finalidad premiar la venta de los productos que se indiquen por el organizador y que comercializa el mismo.
Esta promoción está dirigida únicamente a las Farmacias a las que el organizador haya contactado, por lo que está permitida la participación ni a consumidores particulares ni a otras farmacias con as que el organizado no haya contactado.
Los participantes de esta promoción podrán obtener puntos por la venta de los productos promocionados descritos en estas bases. Al final del periodo promocional, los puntos obtenidos se canjearán por una tarjeta regalo de Amazon, de acuerdo con lo establecido en estas bases.
La participación será gratuita, de manera que no implique un incremento del precio de los productos promocionados, ni coste adicional, ni para el farmacéutico ni para el consumidor.
</p>
<br/><h2 style="text-align:center;">2.	DURACIÓN DE LA PROMOCIÓN.</h2><br/>
<p class="forgot-password__card-subtitle" style="text-align:justify;">
El periodo promocional para la venta de los productos promocionados tiene su inicio el día 01/06/2021 y su fin el día 31/12/2022. Los participantes podrán por tanto registrar sus ventas desde el día 01/06/2021. Podrá participar únicamente el titular de la farmacia de la que se hagan los pedidos.
El organizador se reserva el derecho a verificar la identidad real del participante por los medios que estime oportuno.
</p>
<br/><h2 style="text-align:center;">3.	   PRODUCTOS.</h2><br/>
<p class="forgot-password__card-subtitle" style="text-align:justify;">
Los productos promocionados serán los que se relacionen en las presentes bases, u otros productos que se notifiquen con posterioridad a las farmacias que intervengan en la promoción.
</p>
<br/><h2 style="text-align:center;">4.	PREMIOS.</h2><br/>
<p class="forgot-password__card-subtitle" style="text-align:justify;">
Los participantes tendrán derecho a tener una tarjeta regalo digital Amazon por 20€, por cada 20 puntos conseguidos, obteniéndose cada punto por cada 153,85€ de venta.
Los premios no incluirán ninguna otra prestación, gasto o servicio diferente, a los expresamente indicados en este apartado.
En consecuencia, correrán a cargo del ganador, cualquier prestación o servicio que no se haya indicado expresamente en la descripción anterior.  Si debido a circunstancias más allá del control del organizador, este es incapaz de proporcionar el premio indicado, podrá conceder otro premio equivalente de igual valor.
El premio objeto del presente concurso queda sujeto a estas bases.
El premio no será susceptible de cambio, alteración o compensación a petición del ganador. Si un ganador rechaza el premio no se le ofrecerá ningún premio alternativo, ni podrá ser objeto de negocios jurídicos celebrados con fines comerciales o publicitarios.
</p>
<br/><h2 style="text-align:center;">5.	REQUISITOS DE PARTICIPACIÓN Y MECÁNICA DE LA PROMOCIÓN</h2><br/>
<p class="forgot-password__card-subtitle" style="text-align:justify;">
Pharma Call contactará con las farmacias a las que se le faculte para la presente promoción, y dicha farmacia podrá consultar el estado de los puntos accediendo a la página https://rewards.pharma-call.es/#/login con su usuario y contraseña adquiridos tras el registro en el programa.
El organizador obtendrá los puntos correspondientes en base a la compra de los productos que se promocionen, a raíz de los pedidos realizados por la farmacia.
En el caso de impago de la factura del pedido, se dejará sin efecto los puntos conseguidos, y en caso de devolución del producto, igualmente se quedará sin efecto los puntos conseguidos por ese pedido.
</p>
<br/><h2 style="text-align:center;">6.	PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL</h2><br/>
<p class="forgot-password__card-subtitle" style="text-align:justify;">
En cumplimiento de lo previsto en la Ley Orgánica de Protección de Datos de carácter personal que voluntariamente facilite mediante su participación en esta promoción se incorporarán a un fichero propiedad del organizador con la exclusiva finalidad de gestionar su participación. Al registrarse, el participante autoriza expresamente al organizador para que pueda utilizar los datos facilitados en el formulario de registro, así como aquellos otros que proporcione en el marco de la promoción, con el fin de gestionar la presente promoción y mantenerle informado, incluso mediante correo electrónico sobre cuestiones relacionadas con la promoción y atender las posibles solicitudes de información y/o consultas que efectúen. La base jurídica para llevar a cabo este tratamiento de datos es un consentimiento para participar en esta promoción mediante la aceptación de las presentes bases legales. 
Los datos se conservarán durante el transcurso de la promoción y tras ello durante los plazos de conservación y prescripción de responsabilidades legalmente establecidas.
Igualmente podrá autorizar el participante para consentir de manera expresa el tratamiento de sus datos con la finalidad de remitirle información comercial del organizador, relacionado con sus productos y servicios.
La base jurídica para llevar a cabo este tratamiento de datos es el consentimiento del participante dado para la recepción de dichas comunicaciones comerciales.
            </p>

        </v-card>
    </div>
        <div class="home__copyright">
            <div class="home__copyright__container">
                <span class="copyright">© Copyright - Pharma Call<div style="float:right;"> 
                    <router-link to="/condiciones-de-la-promocion" style="color:white;text-decoration:none;">Condiciones de la promoción</router-link> &nbsp;|&nbsp;
                    <a style="color:white;text-decoration:none;" target="new" href="https://www.pharma-call.com/politica-privacidad/">Política de privacidad</a> &nbsp;|&nbsp; <a target="new" style="color:white;text-decoration:none;" href="https://www.pharma-call.com/politica-de-cookies/">Política de cookies</a> </div></span>
            </div>
        </div>            
  </div>
</v-layout>    
</template>    

<script>

export default {
  name: 'Condiciones de la promoción',
  components: {
  },
  data () {
      return {
      }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
    @import '@/variables';
.login {
        height: calc(100vh - #{$header_size_mobile} - 100px);
        margin-top: calc(#{$header_size_mobile} + 20px);
        @media (min-width: 960px) { 
            height: calc(100vh - #{$header_size_desktop} - 250px);
            margin-top: calc(#{$header_size_desktop} + 35px);
        }
        &__card {
            margin: 0 auto;
            width: 95%;
            max-width: 500px;
            color: $text_color !important;
            @media (min-width: 960px) { 
                margin: 0 auto;
            }
            &-title {
                color: $text_color;
                font-weight: 400 !important;
                font-size: 1.8em;
                text-align: center;
                padding: 15px 0 10px 0;
            }
            &__form {
                margin: 0 20px;
                &-button {
                    display: flex;
                    button {
                        margin: auto;
                    }
                }
                &-login {
                    text-align: center;
                    margin: 20px 0;
                }
                &-error {
                    color: #f44336;
                    text-align: center;
                    font-size: 15px;
                }
            }
        }
    }

    v-main {
        background-color:white !important;
    }
        .home {
        margin-top: $header_auth_size_mobile;
        color: $text_color;
        margin-top:70px!important;        
        background-color:white;
        width: 100%;
        font-weight: 400;        
        margin-bottom:50px !important;
        margin-left: auto;
        margin-right: auto;
        @media (min-width: 960px) { 
            margin-top: $header_auth_size_desktop;
        }
        &__card {
            margin: 0 auto;
            width: 95%;
            margin-bottom:50px !important;
            padding-bottom:50px !important;
            color: $text_color !important;
            @media (min-width: 960px) { 
                margin: 0 auto;
            }
            &-title {
                color: $text_color;
                font-weight: 600 !important;
                font-size: 1.8em;
                text-align: center;
                padding: 20px 20px 10px 20px;
            }
            &-subtitle {
                text-align: left;
                color: $text_color;
                font-weight: 400 !important;
                margin: 0 40px;
            }
            &-button {
                display: flex;
                button {
                    margin: 20px auto;
                }
            }
            &__form {
                padding: 0px 20px 20px 20px;
                &-button {
                    display: flex;
                    button {
                        margin: auto;
                    }
                }
                &-error {
                    color: #f44336;
                    text-align: center;
                    font-size: 15px;
                }
            }
        }        
        &__cupones {
            margin-bottom:40px;
        }
        &__password {
            max-width:1100px;
        }
        &__footer {
            margin-top:20px;
            background-color:#109bc5;
            min-height: 300px;
        }
        &__copyright {
            bottom: 0;
            position: absolute;
            left: 0;
            width: 100%;            
            background-color:#11abd6;
            min-height: 50px;
            &__container {
                color:white;
                margin: 0 auto;
                max-width:1100px;
                padding-top:15px;
                font-size:85%;
            }
        }
        &__banner {
            background-color: $primary-color;
            min-height: 230px;
            padding: 20px;
            color: white;
            text-align: center;
            font-size: 22px;
            display: flex;
            line-height: 28px;
            @media (min-width: 960px) { 
                font-size: 26px;
                line-height: 32px;
            }
            &__text {
                margin: auto auto 30px auto;
                font-weight: bold;
                &-client {
                    color: black;
                }
                &-subtext {
                    font-weight: 400;
                }
            }
        }
        &__points, &__exchange {
            background-color: white;
            border-radius: 10px;
            margin: 20px auto;
            padding: 20px;
            text-align: center;
            color: black;
            max-width: 400px;
            @media (min-width: 600px) {
                margin: 60px 20px 60px 20px;
                padding: 30px;
            }
        }
        &__coupon {            
            border-radius: 10px;
            margin: 5px auto;
            padding: 5px;
            margin-bottom: 40px;
            text-align: center;
            color: black;
            max-width: 600px;
            @media (min-width: 600px) {                
                padding: 30px;
            }
            &-icon {
                color: black;                
                border-radius: 50%;
                font-size: 40px;
                padding: 3px;
            }
            &-text {
                margin-top: 10px;
                font-weight: bold;
                font-size: 13px;
                margin-bottom: 0;
            }
            &-coupon {
                font-size: 30px;
                color: $primary-color;
                padding:5px;
                font-weight: bold;
                margin-bottom: 0;
            }
            &-subtext {
                color: black;
                margin-bottom: 0;
                font-size: 12px;
            }               
        }
        &__points {
            &-icon {
                color: white;
                background-color: black;
                border-radius: 50%;
                font-size: 15px;
                padding: 3px;
            }
            &-text {
                margin-top: 10px;
                margin-bottom: 0;
            }
            &-points {
                font-size: 30px;
                color: $primary-color;
                font-weight: bold;
                margin-bottom: 0;
            }
            &-subtext {
                color: $primary-color;
                margin-bottom: 0;
            }
        }
        &__exchange {
            &-title {
                font-weight: bold;
                margin-bottom: 6px;
            }
            &-subtitle {
                color: $text_color;
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 20px;
            }
            &-button {
                text-transform: none;
                padding: 20px 15px !important;
            }
        }
        &__personal {
            background-color: white;
            padding: 60px 20px;
            &-title {
                text-align: center;
                font-weight: 400;
                color: $primary-color;
                margin-bottom: 30px;
            }
            &__cards {
                @media (min-width: 600px) {
                    display: flex;
                }
                &__card {
                    border: 2px solid $primary-color;
                    display: flex;
                    margin: 10px 20px;
                    padding: 15px;
                    cursor: pointer;
                    &:hover {
                        transform: scale(1.05);
                    }
                    &-icon {
                        color: $primary-color;
                        margin-right: 8px;
                    }
                    &-text {
                        color: $text-color;
                        font-size: 14px;
                        margin: auto;
                    }
                }
            }
        }
        &__info {
            background-color: white;
            max-width: 1100px;            
            margin: 0 auto;
            padding: 30px 10px;
            &-title {
                text-align: center;
                font-weight: 400;
                color: $primary-color;
                margin-bottom: 10px;
            }
            &-subtitle {
                color: $text-color;
                text-align: center;
                font-size: 14px;
                line-height: 18px;
                max-width: 700px;
                margin: auto;
                padding-bottom: 50px;
            }
            &__cards {
                display: flex;
                &__card {
                    position: relative;
                    max-width: 80%;
                    padding: 10;
                    line-height: 0;
                    img {
                        width: 100%;
                        height: auto;
                        overflow: hidden;
                        border-radius: 50%;
                    }
                    &-text {
                        position: absolute;
                        top: 50%;
                        width: 100%;
                        text-align: center;
                        color: white;
                    }
                    &-overlay {
                        opacity: 0.5;
                        background-color: #333333;
                        position: absolute;
                        border-radius: 50%;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                    }
                }
            }
        }
    }
    .forgot-password {
        height: calc(100vh - #{$header_size_mobile});
        margin-top: calc(#{$header_size_mobile} + 20px);
        @media (min-width: 960px) { 
            height: calc(100vh - #{$header_size_desktop} - 250px);
            margin-top: calc(#{$header_size_desktop} + 35px);
        }
        &__card {
            margin: 0 auto;
            width: 95%;
            max-width: 600px;
            color: $text_color !important;
            @media (min-width: 960px) { 
                margin: 0 auto;
            }
            &-title {
                color: $text_color;
                font-weight: 400 !important;
                font-size: 1.8em;
                text-align: center;
                padding: 20px 20px 10px 20px;
            }
            &-subtitle {
                text-align: left;
                color: $text_color;
                font-weight: 400 !important;
                margin: 0 40px;
            }
            &-button {
                display: flex;
                button {
                    margin: 20px auto;
                }
            }
            &__form {
                padding: 0px 20px 20px 20px;
                &-button {
                    display: flex;
                    button {
                        margin: auto;
                    }
                }
                &-error {
                    color: #f44336;
                    text-align: center;
                    font-size: 15px;
                }
            }
        }
    }
</style>
